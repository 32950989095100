
html, body, #root {
    width: 100%;
    height: 100%;
}

.homepage {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;


    & .array {
        width: 95%;
        height: calc(100% - 5px);
        margin-top: 5px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: rgb(39, 39, 39);
        
        &__header {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            background: rgb(0,52,94);
            background: linear-gradient(180deg, rgba(0,52,94,1) 0%, rgba(0,44,80,1) 50%, rgba(0,52,94,1) 100%);            border-top-right-radius: 10px;


            & div {
                width: calc(100% / 5 - (40px / 5));
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }

        & .delete {
            width: 40px;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            & div {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                & svg {
                    color: red;
                }

            }

        }

        &__content {
            background-color: rgb(0, 17, 31);
            height: calc(100% - 50px);
            color: white;
            overflow-y: scroll;
            
            & > div {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                height: 50px;
                border-bottom: 2px solid rgba(255, 255, 255, 0.575);

                &:last-child {
                    border: inherit;
                    margin-bottom: 30px;
                }

                & > div {
                    width: calc(100% / 5 - (40px / 5));
                    text-align: center;
                }
            }
        }
    }
}

.add {
    position: fixed;

    bottom: 0;
    left : 40%; 
    transform: translateX(-53%);
    width : 300px ; 
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    & .estimate {
        position: absolute;
        background-color:#e00227 ; 
        left: 100%;
        bottom: 0;
        width: 310px;
        overflow: hidden;

        & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            & h1 {
                color: white;
                font-size: 16px;
                width: 95%;
                border-bottom: 1px solid rgba(255, 255, 255, 0.452);
                margin-bottom: 10px;
                text-align: center;
                padding-bottom: 10px;
            }

            & p {
                color: white;
            }

            & > div {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                & > div {
                    width: 50%;
                    display: flex;
                    flex-direction: column;

                    & p {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:first-child {
                            font-style: italic;
                            font-size: 14px;
                            color: rgba(255, 255, 255, 0.747);
                            margin-bottom: 10px;
                        }
                        &:last-child {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }


    &__title {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: rgb(224, 2, 39);
        cursor: pointer;

        & svg {
            position: absolute;
            right: 10px;
        }

    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: rgb(226, 226, 226);
        overflow-y: scroll;

        & p {
            margin: 0;
        }

        & > div {
            width: 95%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        & .selectStrategy {
            & > div {
                border: 1px solid rgb(0, 17, 31);
                height: 30px;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 30px;
                background-color: rgb(0, 17, 31);
                color: white;
                position: relative;

                & p {
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
                & .background {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none!important;
                    z-index: 1000 ; 
                    background-color: transparent!important;
                }

                & > div {
                    position: absolute;
                    background-color: #002c50;
                    overflow-y: scroll;
                    height: 150px;
                    width: 130%;
                    top: 110%;
                    border-radius: 10px;
                    z-index: 2000;




                    & > div {
                        width: 95%;
                        height: 40px;
                        border-bottom: 2px solid rgba(255, 255, 255, 0.432);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: auto;
                        margin-right: auto;
                        cursor: pointer;
                    }

                }


            }

            & .minMax {
                display: flex;
                justify-content: space-between;
                width: 60%;
                margin-top: 10px;
                background-color: inherit;
                border: inherit;

                & p {
                    font-style: italic;
                    font-size: 14px;
                    color: #002c50a2;
                    text-align: center;
                }

            }
        }

        & .amount {
            & .paste {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-style: italic;
                width: 80%;
                text-align: center;
                cursor: pointer;
                
            }

            & input {
                margin-top: 15px;
                height: 25px;
                text-align-last: center;
                width: 40%;
            }

            & .minMax {
                display: flex;
                justify-content: space-between;
                width: 60%;
                margin-top: 5px;

                & p {
                    font-style: italic;
                    font-size: 14px;
                    color: #002c50a2;
                    text-align: center;
                }

            }
        }

        & button {
            margin: 10px;
            height: 30px;
            background-color: #e00227;
            border: none;
            width: 40%;
            color: white;
            font-weight: bold;
            border-radius: 10px;
            cursor: pointer;
        }
    }

    & .label {
        width: 80%;
        color: rgb(0, 17, 31);
        border-bottom: 1px solid rgb(0, 17, 31);
        margin-bottom: 20px;
        text-align: center;
        padding-bottom: 10px;

        &:first-child {
            margin-top: 20px;
        }
    }


}


.summary {
    position: fixed;
    bottom: 0;
    right : 40%; 
    transform: translateX(53%);
    width : 300px ; 
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &__title {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: rgb(224, 2, 39);
        cursor: pointer;

        & svg {
            position: absolute;
            right: 10px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: rgb(226, 226, 226);

        & p {
            margin: 0;
        }

        & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & h1 {
                width: 80%;
                color: rgb(0, 17, 31);
                border-bottom: 1px solid rgb(0, 17, 31);
                margin-bottom: 10px;
                text-align: center;
                padding-bottom: 10px;
                font-size: 16px;
                font-weight: normal;
                &:first-child {
                    margin-top: 20px;
                }
            }

            & > p {
                width: 90%;
                text-align: center;
                font-weight: bold;
                color: #002e54;
            }

            & > div {
                display: flex;
                width: 100%;
                justify-content: space-around;

                & > div {
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;



                    & p {
                        &:first-child {
                            font-style: italic;
                            font-size: 14px;
                            color: rgb(0, 17, 31);
                            margin-bottom: 10px;
                        }

                        &:last-child {
                            font-weight: bold;
                            color: #002e54;
                        }
                    }
                }
            }
        }
    }
}

.noData {
    height: auto ; 
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    & p {
        margin: 0;
    }

}