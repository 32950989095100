html, body, #root {
  width: 100%;
  height: 100%;
}

.homepage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.homepage .array {
  width: 95%;
  height: calc(100% - 5px);
  margin-top: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #272727;
}
.homepage .array__header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background: #00345e;
  background: linear-gradient(180deg, #00345e 0%, #002c50 50%, #00345e 100%);
  border-top-right-radius: 10px;
}
.homepage .array__header div {
  width: calc(20% - 8px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.homepage .array .delete {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage .array .delete div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage .array .delete div svg {
  color: red;
}
.homepage .array__content {
  background-color: #00111f;
  height: calc(100% - 50px);
  color: white;
  overflow-y: scroll;
}
.homepage .array__content > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.575);
}
.homepage .array__content > div:last-child {
  border: inherit;
  margin-bottom: 30px;
}
.homepage .array__content > div > div {
  width: calc(20% - 8px);
  text-align: center;
}

.add {
  position: fixed;
  bottom: 0;
  left: 40%;
  transform: translateX(-53%);
  width: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.add .estimate {
  position: absolute;
  background-color: #e00227;
  left: 100%;
  bottom: 0;
  width: 310px;
  overflow: hidden;
}
.add .estimate > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add .estimate > div h1 {
  color: white;
  font-size: 16px;
  width: 95%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.452);
  margin-bottom: 10px;
  text-align: center;
  padding-bottom: 10px;
}
.add .estimate > div p {
  color: white;
}
.add .estimate > div > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.add .estimate > div > div > div {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.add .estimate > div > div > div p {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add .estimate > div > div > div p:first-child {
  font-style: italic;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.747);
  margin-bottom: 10px;
}
.add .estimate > div > div > div p:last-child {
  font-weight: bold;
}
.add__title {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #e00227;
  cursor: pointer;
}
.add__title svg {
  position: absolute;
  right: 10px;
}
.add__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #e2e2e2;
  overflow-y: scroll;
}
.add__content p {
  margin: 0;
}
.add__content > div {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add__content .selectStrategy > div {
  border: 1px solid #00111f;
  height: 30px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #00111f;
  color: white;
  position: relative;
}
.add__content .selectStrategy > div p {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add__content .selectStrategy > div .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none !important;
  z-index: 1000;
  background-color: transparent !important;
}
.add__content .selectStrategy > div > div {
  position: absolute;
  background-color: #002c50;
  overflow-y: scroll;
  height: 150px;
  width: 130%;
  top: 110%;
  border-radius: 10px;
  z-index: 2000;
}
.add__content .selectStrategy > div > div > div {
  width: 95%;
  height: 40px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.432);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.add__content .selectStrategy .minMax {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 10px;
  background-color: inherit;
  border: inherit;
}
.add__content .selectStrategy .minMax p {
  font-style: italic;
  font-size: 14px;
  color: #002c50a2;
  text-align: center;
}
.add__content .amount .paste {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-style: italic;
  width: 80%;
  text-align: center;
  cursor: pointer;
}
.add__content .amount input {
  margin-top: 15px;
  height: 25px;
  text-align-last: center;
  width: 40%;
}
.add__content .amount .minMax {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 5px;
}
.add__content .amount .minMax p {
  font-style: italic;
  font-size: 14px;
  color: #002c50a2;
  text-align: center;
}
.add__content button {
  margin: 10px;
  height: 30px;
  background-color: #e00227;
  border: none;
  width: 40%;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}
.add .label {
  width: 80%;
  color: #00111f;
  border-bottom: 1px solid #00111f;
  margin-bottom: 20px;
  text-align: center;
  padding-bottom: 10px;
}
.add .label:first-child {
  margin-top: 20px;
}

.summary {
  position: fixed;
  bottom: 0;
  right: 40%;
  transform: translateX(53%);
  width: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.summary__title {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #e00227;
  cursor: pointer;
}
.summary__title svg {
  position: absolute;
  right: 10px;
}
.summary__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #e2e2e2;
}
.summary__content p {
  margin: 0;
}
.summary__content > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.summary__content > div h1 {
  width: 80%;
  color: #00111f;
  border-bottom: 1px solid #00111f;
  margin-bottom: 10px;
  text-align: center;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: normal;
}
.summary__content > div h1:first-child {
  margin-top: 20px;
}
.summary__content > div > p {
  width: 90%;
  text-align: center;
  font-weight: bold;
  color: #002e54;
}
.summary__content > div > div {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.summary__content > div > div > div {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.summary__content > div > div > div p:first-child {
  font-style: italic;
  font-size: 14px;
  color: #00111f;
  margin-bottom: 10px;
}
.summary__content > div > div > div p:last-child {
  font-weight: bold;
  color: #002e54;
}

.noData {
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.noData p {
  margin: 0;
}

